const errorsByLang = {
  BR: {
    RATE_LIMIT_CARD:
      'O sistema de auditoria verificou que você fez várias tentativas com o mesmo cartão e para evitar possíveis fraudes esta transação foi bloqueada.',
    TOO_MANY_REQUESTS:
      'Ops, parece que houve várias tentativas. Espere o desbloqueio para tentar novamente.',
    CREDIT_CARD_INVALID: 'Revise o número do cartão digitado.',
    ACCOUNT_ALREADY_EXISTS:
      'Já existe uma conta ativa com os dados informados. Em caso de dúvidas, entre em contato com a Central de Ajuda dentro do seu perfil.',
    PHONE_NUMBER_INVALID: 'Revise o número do telefone digitado.',
    SOURCE_PHONE_NUMBER_INVALID: 'Este número já está cadastrado no sistema.',
    CARD_REJECTED:
      'Entre em contato com a sua operadora para solucionar ou tente novamente.',
    CREDIT_CARD_REJECTED:
      'Entre em contato com a sua operadora para solucionar ou tente novamente.',
    'CREDIT_CARD+CREDIT_CARD_REJECTED':
      'Entre em contato com a sua operadora para solucionar ou tente novamente.',
    SUBSCRIPTION_NOT_FOUND: 'Assinatura Wiseu Up Online não encontrada.',
    NOT_ELIGIBLE:
      'O e-mail informado não é elegível para essa ação promocional.',
    DEFAULT_ERROR: 'Ocorreu um erro inesperado. Tente novamente mais tarde.',
    ERROR_PAYMENT:
      'Transação não autorizada pelo seu banco. Entre em contato com a sua operadora para solucionar ou tente novamente com outro cartão.',
    INVOICE_NOT_FOUND: 'Fatura não encontrada',
    INVOICE_ALREADY_PAID: 'Esta fatura já está paga.',
    INVOICE_CANNOT_BE_PAID: 'Esta fatura não pode ser paga por este meio.',
    NO_PURCHASE: 'Compra do lead não encontrada.',
    APPLICATION_ERROR: 'Já existe uma conta criada com este e-mail.',
    PAY_ERROR:
      'Transação não autorizada pelo seu banco. Entre em contato com a sua operadora para solucionar ou tente novamente com outro cartão.',
    CANCELLED_BY_INSTITUTION:
      'Verifique se o CPF informado possui conta no Nubank ou se ocorreu algum erro ao realizar o pagamento pelo aplicativo.',
    TICKET_SOLD_OUT:
      'Não se preocupe, você pode aproveitar para acompanhar o evento online.',
    PLAN_NOT_FOUND:
      'Oferta não encontrada ou inativa. Em caso de dúvidas consulte o responsável pela oferta',
    MULTIPLE_ATTEMPTS:
      'Pagamento em processamento. Verifique seu e-mail ou aguarde antes de tentar novamente.',
    VALIDATION_ERROR:
      'Identificamos um problema com as informações enviadas. Por favor, revise os dados e tente novamente',
    PAYMENT_PROFILE_IS_INVALID: 'Por favor, cadastre um cartão válido',
  },
  MZ: {
    RATE_LIMIT_CARD:
      'O sistema de auditoria verificou que você fez várias tentativas com o mesmo cartão e para evitar possíveis fraudes esta transação foi bloqueada.',
    TOO_MANY_REQUESTS:
      'Ops, parece que houve várias tentativas. Espere o desbloqueio para tentar novamente.',
    CREDIT_CARD_INVALID: 'Revise o número do cartão digitado.',
    ACCOUNT_ALREADY_EXISTS:
      'Já existe uma conta ativa com os dados informados. Em caso de dúvidas, entre em contato com a Central de Ajuda dentro do seu perfil.',
    PHONE_NUMBER_INVALID: 'Revise o número do telefone digitado.',
    SOURCE_PHONE_NUMBER_INVALID: 'Este número já está cadastrado no sistema.',
    CARD_REJECTED:
      'Transacção não autorizada. Entre em contato com a sua operadora para solucionar ou tente novamente.',
    CREDIT_CARD_REJECTED:
      'Transacção não autorizada. Entre em contato com a sua operadora para solucionar ou tente novamente.',
    'CREDIT_CARD+CREDIT_CARD_REJECTED':
      'Transacção não autorizada. Entre em contato com a sua operadora para solucionar ou tente novamente.',
    SUBSCRIPTION_NOT_FOUND: 'Assinatura Wiseu Up Online não encontrada.',
    NOT_ELIGIBLE:
      'O e-mail informado não é elegível para essa ação promocional.',
    DEFAULT_ERROR: 'Ocorreu um erro inesperado. Tente novamente mais tarde.',
    ERROR_PAYMENT:
      'Transação não autorizada pelo seu banco. Entre em contato com a sua operadora para solucionar ou tente novamente com outro cartão.',
    INVOICE_NOT_FOUND: 'Fatura não encontrada',
    INVOICE_ALREADY_PAID: 'Esta fatura já está paga.',
    INVOICE_CANNOT_BE_PAID: 'Esta fatura não pode ser paga por este meio.',
    NO_PURCHASE: 'Compra do lead não encontrada.',
    APPLICATION_ERROR: 'Já existe uma conta criada com este e-mail.',
    PAY_ERROR:
      'Transação não autorizada pelo seu banco. Entre em contato com a sua operadora para solucionar ou tente novamente com outro cartão.',
    PLAN_NOT_FOUND:
      'Oferta não encontrada ou inativa. Em caso de dúvidas consulte o responsável pela oferta',
    MULTIPLE_ATTEMPTS:
      'Pagamento em processamento. Verifique seu e-mail ou aguarde antes de tentar novamente.',
    VALIDATION_ERROR:
      'Identificamos um problema com as informações enviadas. Por favor, revise os dados e tente novamente',
  },
};

const errorsTitle = {
  BR: {
    RATE_LIMIT_CARD: 'Muitas tentativas!',
    TOO_MANY_REQUESTS: 'Muitas tentativas',
    CREDIT_CARD_INVALID: 'Número de cartão inválido',
    DEFAULT_ERROR: 'Mensagem de erro',
    SOURCE_PHONE_NUMBER_INVALID: 'Número de telefone inválido',
    PHONE_NUMBER_INVALID: 'Número de telefone inválido',
    TICKET_SOLD_OUT: 'Os ingressos presenciais esgotaram',
    CARD_REJECTED: 'Transação não autorizada',
    CREDIT_CARD_REJECTED: 'Transação não autorizada',
    'CREDIT_CARD+CREDIT_CARD_REJECTED': 'Transação não autorizada',
    PAYMENT_PROFILE_IS_INVALID: 'Cartão inválido',
  },
  MZ: {
    RATE_LIMIT_CARD: 'Muitas tentativas!',
    TOO_MANY_REQUESTS: 'Muitas tentativas',
    CREDIT_CARD_INVALID: 'Número de cartão inválido',
    DEFAULT_ERROR: 'Mensagem de erro',
    SOURCE_PHONE_NUMBER_INVALID: 'Número de telefone inválido',
    PHONE_NUMBER_INVALID: 'Número de telefone inválido',
  },
};

export const getErrorMessage = (operation = 'BR', code = 'DEFAULT_ERROR') => {
  return errorsByLang[operation][code] || errorsByLang[operation].DEFAULT_ERROR;
};

export const getErrorTitle = (operation = 'BR', code = 'DEFAULT_ERROR') => {
  return errorsTitle[operation][code] || errorsTitle[operation].DEFAULT_ERROR;
};

const alertCodes = [
  'CREDIT_CARD_REJECTED',
  'CREDIT_CARD+CREDIT_CARD_REJECTED',
  'CARD_REJECTED',
  'MULTIPLE_ATTEMPTS',
  'VALIDATION_ERROR',
  'PAYMENT_PROFILE_IS_INVALID',
];

export const getFeedbackType = (code = 'DEFAULT_ERROR') => {
  if (alertCodes.includes(code)) {
    return 'alert';
  }

  return 'error';
};
