import styled, { css } from 'styled-components';
import { convertHexToRGBA } from 'utils/convertHexToRgba';

export const Wrapper = styled.div<{ type: 'success' | 'error' | 'alert' }>`
  ${({ theme, type }) => css`
    width: 100%;
    padding: 16px;
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;

    img:last-child {
      cursor: pointer;
    }

    dsb-icon:last-child {
      cursor: pointer;
    }

    ${!!alertBoxModifiersByType[type] && alertBoxModifiersByType[type](theme)}
  `}
`;

const alertBoxModifiersByType: Record<'error' | 'alert' | 'success', any> = {
  error: (theme) => css`
    background-color: ${convertHexToRGBA(
      theme.color.feedback.error.pure,
      0.16
    )};
    border: 0.5px solid ${theme.color.feedback.error.pure};
  `,
  alert: (theme) => css`
    background-color: ${convertHexToRGBA(
      theme.color.feedback.alert.pure,
      0.16
    )};
    border: 0.5px solid ${theme.color.feedback.alert.pure};
  `,
  success: (theme) => css`
    background-color: ${convertHexToRGBA(
      theme.color.feedback.success.pure,
      0.16
    )};
    border: 0.5px solid ${theme.color.feedback.success.pure};
  `,
};

export const Content = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: 0 ${theme.spacing.xxxs} 0 ${theme.spacing.insetNano};
    display: flex;
    box-sizing: border-box;
  `}
`;

export const MessageGroup = styled.div`
  display: flex;
  flex-direction: column;

  dsb-typography:first-child {
    margin-bottom: 4px;
  }

  dsb-typography[id='call-center-message'] {
    margin-top: 16px;

    a {
      color: var(--neutral-color-high-contrast);
    }
  }
`;

export const IconContainer = styled.div``;

export const FaqLink = styled.a`
  text-decoration: underline;
`;
