import React, { useCallback, useEffect, useRef } from 'react';

import * as S from './styles';
import { DsIcon, DsTypography } from '../';
import { IconIconAttribute } from '@wisereducacao-npm/design-system-core-web/dist/components/icon/types';
import { TypographyColorAttribute } from '@wisereducacao-npm/design-system-core-web/dist/components/typography/types';
import { useCart } from 'hooks/useCart';

interface FeedbackBoxProps {
  type: 'success' | 'error' | 'alert';
  message: string;
  title: string;
  scrollToTop: boolean;
  showCallCenterLink?: boolean;
  onClose?: VoidFunction;
}

const icon: Record<string, IconIconAttribute> = {
  success: 'check_circle',
  alert: 'warning_circle',
  error: 'warning',
};

const color: Record<string, TypographyColorAttribute> = {
  success: 'feedback-success-pure',
  alert: 'feedback-alert-pure',
  error: 'feedback-error-pure',
};

export function FeedbackBox({
  message,
  title,
  type = 'error',
  scrollToTop,
  showCallCenterLink,
  onClose,
}: FeedbackBoxProps) {
  const context = useCart();
  const componentRef = useRef(null);

  const executeScroll = useCallback(
    () =>
      scrollToTop
        ? window?.scrollTo({
            behavior: 'smooth',
            top: 0,
            left: 0,
          })
        : componentRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          }),
    [scrollToTop]
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      !!componentRef && executeScroll();
    }, 200);

    return () => clearTimeout(timeoutId);
  }, [executeScroll]);

  return (
    <S.Wrapper type={type}>
      <DsIcon icon={icon[type]} color={color[type]} />

      <S.Content>
        <S.MessageGroup>
          <DsTypography
            component="paragraph"
            size="small-bold"
            color="neutral-pure"
          >
            {title}
          </DsTypography>
          <DsTypography component="paragraph" size="small" color="neutral-high">
            {message}
          </DsTypography>
          {showCallCenterLink && (
            <DsTypography
              id="call-center-message"
              component="paragraph"
              size="small"
              color="neutral-high"
            >
              Caso tenha dúvidas sobre, acesse nossa{' '}
              <S.FaqLink
                target="_blank"
                href={context?.productTheme?.data?.faqLink}
                rel="noreferrer"
              >
                central de atendimento
              </S.FaqLink>
              .
            </DsTypography>
          )}
        </S.MessageGroup>
      </S.Content>

      {onClose && (
        <S.IconContainer onClick={onClose}>
          <DsIcon icon="x" data-testid="close-icon" />
        </S.IconContainer>
      )}
    </S.Wrapper>
  );
}
